import React from "react";
import {
  AboutSection,
  Page,
  Seo,
} from "gatsby-theme-portfolio-minimal";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/styles.css"
import Video from "../components/video";
import ExternalScript from "../components/externalScript";
import NowPlaying from "../components/nowPlaying";
import SocialNetworkBar from "../components/socialNetworkBar";
import ContactUs from "../components/contactUs";

export default function IndexPage() {
  return (
    <>
      {<Seo title="Camel Van band website" />}
      <Page useSplashScreenAnimation>
        <div className="imageHeader">        
          <StaticImage src="../../content/images/CamelBandLogo_transparent.png" layout="constrained" alt="Camel Van logo" />
          <SocialNetworkBar/>
        </div>
        <NowPlaying />
        <div>
          <StaticImage src="../../content/images/camel_van_show.jpg" layout="constrained" alt="Camel Van logo" />
          <ExternalScript />
        </div>
        <AboutSection sectionId="about" heading="About us" />
        <Video
          videoSrcURL="https://www.youtube.com/embed/ZOYItNwaGj4"
          videoTitle="Camel van - Lipstick"
        />
        <ContactUs sectionId="contactus" heading="Contact us" />
      </Page>
    </>
  );
  
}

