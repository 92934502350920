import React from "react";

class NowPlaying extends React.Component {
ENABLED = false;
API_URL="https://us-central1-utility-node-92923.cloudfunctions.net/setlist"

state = {
    songTitle: [],
    songUrl: [],
    songImageSrc: [],
    venue: [],
    done: false
}

componentDidMount() {
    fetch(this.API_URL)
        .then(res => res.json())
        .then(result => this.setState({ songTitle: result.songTitle , songUrl: result.songUrl, venue: result.venue, songImageSrc: result.songImageSrc, done: true}))
}


    render(){
        if(!this.state.done || !this.ENABLED) {
            return(null);
        } else if (this.ENABLED){
            return (
                <div id="nowPlayingSong" className="nowPlaying">
                    <h1>Now playing at {this.state.venue} </h1> 
                        <img src={this.state.songImageSrc} />
                        <h1>
                            <a id="songName" target="_blank" href={this.state.songUrl} >
                            {this.state.songTitle}            
                            </a>
                        </h1>
                    
                </div>
          );
        }
    } 
}

export default NowPlaying