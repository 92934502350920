import React from "react";

class ExternalScript extends React.Component {
componentDidMount(){
    const script = document.createElement("script");
    script.src="https://widget.songkick.com/10182960/widget.js";
    script.async = true;
    document.getElementById('tickets').append(script);
}

    render(){
        return (
            <div className="concertScript">
                <h2>Come to one of our shows!</h2>
                
                <a id="tickets" href="https://www.songkick.com/artists/10182960" className="songkick-widget" data-theme="light" data-detect-style="true" data-locale="en">Upcoming concerts</a>
            </div>
          );
    }
}

export default ExternalScript