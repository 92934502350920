import React from "react";
import { StaticImage } from "gatsby-plugin-image";

class ContactUs extends React.Component {
    render(){
        return (
            <div  className="contactUs lightTheme">
                <StaticImage class="mailImage" src="../../content/images/mail.png" layout="constrained" alt="Contact us" />
                <a  class="camelEmail"  href="mailto:booking@camelvanband.com">booking@camelvanband.com</a>
            </div>
          );
    }
}

export default ContactUs