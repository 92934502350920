import React from "react";
import { SocialIcon } from 'react-social-icons';

class SocialNetworkBar extends React.Component {
    render(){
        return (
            <div className="socialNetworkBar">
                <SocialIcon url="https://www.instagram.com/camelvanband/" target="_blank"/>
                <SocialIcon url="https://open.spotify.com/artist/7eOLFhnnpMCX5i7IgS5PoK" target="_blank"/>
                <SocialIcon url="https://www.youtube.com/channel/UCtnknukeEmHf8y4GrmxDqnQ" target="_blank"/>
                <SocialIcon url="https://www.facebook.com/camelvanband/" target="_blank"/>
            </div>
          );
    }
}

export default SocialNetworkBar